'use client';

import React, { FC } from 'react';

import classNames from 'classnames';

import { defaultDarkTheme, defaultLightTheme, useTheme } from '@/src/modules/common/hooks/useTheme';
import { CommonStyledComponent } from '@/src/modules/common/types/common.types';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';

export type ThemeSwitcher2Props = {
	tipToDark?: string;
	tipToLight?: string;
	tipToSelect?: string;
	themeFromCookie?: string;
} & CommonStyledComponent;

export const ThemeSwitcher2: FC<ThemeSwitcher2Props> = ({ className, tipToDark, tipToLight }) => {
	const { setTheme, currentSchema } = useTheme();

	return (
		<>
			<Tooltip target=".theme-switcher" />

			<Button
				text
				rounded
				size="small"
				icon={`pi ${currentSchema === 'dark' ? 'pi-moon' : 'pi-sun'}`}
				className={classNames('text-color theme-switcher', className)}
				aria-label="Theme toggle"
				data-pr-tooltip={currentSchema === 'dark' ? tipToLight : tipToDark}
				data-pr-position="bottom"
				onClick={() =>
					currentSchema === 'dark'
						? setTheme(defaultLightTheme)
						: setTheme(defaultDarkTheme)
				}
				aria-controls="popup_menu_left"
				aria-haspopup
				style={{ marginRight: '-14px' }}
			/>
		</>
	);
};
