import {
	EntityNumber,
	LocaleMessageType,
	LocaleMessageTypeArray,
} from '@/src/modules/common/models/common.models';
import { ProjectEntity } from '@/src/modules/events/models/projects.models';
import { SeasonEntity } from '@/src/modules/events/models/seasons.models';
import {
	Content,
	ContentStatus,
	Invariant,
	InvariantArray,
	ObjectStatus,
} from '@/src/modules/srm/models/squidex.models';

export type EventDate = {
	endDay: number;
	endMonth: LocaleMessageType;
	endTimestamp: string;
	startDay: number;
	startMonth: LocaleMessageType;
	startTimestamp: string;
};

export type EventDatesAlt = {
	hint: LocaleMessageType;
	days: Record<string, EventDatesType>;
};

export type EventCfpStatus = 'FUTURE' | 'PUBLIC' | 'PRIVATE' | 'RESTRICTED' | 'CLOSED';
export type EventDatesType = 'OFFLINE' | 'ONLINE' | 'HYBRID';
export type EventPlatform = 'JUGRU' | 'YOUTUBE' | 'VK_VIDEO';
export type EventType = 'CONFERENCE' | 'MEETUP';

export type EventModules = {
	readonly modules?: EventModule[];
}

export type EventEntity = {
	readonly cfpStatus?: EventCfpStatus | string;
	readonly datesAlt?: EventDatesAlt;
	readonly hint?: string;
	readonly platform?: EventPlatform;
	readonly seasonId?: number;
	readonly type?: EventType;
	readonly venueId?: number;
	readonly version?: string;
	readonly projectId?: string;
	readonly content?: EventContent;
	readonly workspaceId?: string;

	readonly slug?: string;

	// deprecated
	// readonly jira?: JiraProjectVersion;

	readonly dates?: {
		days: Record<string, EventDatesType>;
		intervals: {
			lowerBoundary: string;
			upperBoundary: string;
			type: string;
		}[];
		source: string;
		status: ContentStatus;
		timezone: string;
		total: { lowerBoundary: string; upperBoundary: string };
	};
	readonly stage?: EventStage;
	// readonly phase?: EventPhase;

	// computed
	isFinished?: boolean;
	rights?: Record<EventRightsTypes, boolean>;
	season?: SeasonEntity;
	project?: ProjectEntity;
} & EntityNumber &
	EventStatusEntity &
	EventModules;

export type EventModule = {
	readonly name: EventModuleName;
	// computed
	rights?: Record<EventModuleRightsTypes, boolean>;
} & EntityNumber;

export type EventModuleName =
	'event/team'
	| 'event/publication'
	| 'event/schedule'
	| 'event/activity-feedback'
	| 'event/activity-report'
	| 'event/schedule-broadcast'
	| 'event/schedule-support';

export enum EventModuleRightsTypes {
	canViewPublicationsModule = 'canViewPublicationsModule',
	canListProposalsForPublications = 'canListProposalsForPublications',

	canViewScheduleModule = 'canViewScheduleModule',
	canViewScheduleBroadcastModule = 'canViewScheduleBroadcastModule',
	canViewScheduleSupportModule = 'canViewScheduleSupportModule',

	canListScheduleVersions = 'canListScheduleVersions',
	canListProposalsForSchedule = 'canListProposalsForSchedule',

	canViewProgramModule = 'canViewProgramModule',

	canViewTeamModule = 'canViewTeamModule',
	canListMembers = 'canListMembers',

	canViewEventReportModule = 'canViewEventReportModule',
	canViewEventActivityFeedbackModule = 'canViewEventActivityFeedbackModule',
}

export enum EventRightsTypes {
	canRead = 'canRead',
	canPatch = 'canPatch',
	canArchive = 'canArchive',
	canFindMember = 'canFindMember',
	canListMembers = 'canListMembers',
	canAddMember = 'canAddMember',
}

interface EventContentData {
	readonly title?: LocaleMessageType;
	readonly description?: LocaleMessageType;
	readonly brandColors?: InvariantArray<string>;
	readonly coloredLogo?: LocaleMessageTypeArray;
	readonly logoUri?: Invariant<string>;
	readonly monochromeLogo?: LocaleMessageTypeArray;
	readonly playlistUri?: Invariant<string>;
	readonly siteUri?: LocaleMessageType;

	readonly activityLinkPattern?: LocaleMessageType;
	readonly cfpUri?: LocaleMessageType;
	readonly personalLinkPattern?: LocaleMessageType;

	// disabled
	readonly srmId?: Invariant<string>;
	// disabled
	readonly srmProjectId?: Invariant<string>;
	// disabled
	readonly srmVersion?: Invariant<string>;

	// deprecated
	// readonly name?: LocaleMessageType;
	// readonly datesApproved?: boolean;
	// readonly datesDetermined?: boolean;
	readonly period?: EventPeriod;
	// readonly season?: SeasonEntity;
}

export type EventContent = Content<EventContentData>;

export type EventObjectStatus = ObjectStatus & 'reactivated';

export type EventStatusEntity = {
	readonly objectStatus?: EventObjectStatus;
};

export type EventPeriod = {
	readonly startsAt: string;
	readonly endsAt: string;
};

export type EventStage = 'UNKNOWN' | 'PLANNING' | 'PREPARING' | 'PROGRESSING' | 'COMPLETED';

// export type EventPhase = {
// 	readonly name?: 'Future event';
// 	readonly startsAt?: Date;
// 	readonly finishedAt?: Date;
// 	readonly stage?: EventStage;
// 	readonly type?: 'begin_phase' | 'phase' | 'end_phase';
// 	readonly alertingEnabled?: boolean;
// 	readonly alertingInterval?: null;
// };
