'use client';

import { useCallback, useEffect, useMemo, useState } from 'react';

import { useLocalStorageState } from '@/src/modules/common/hooks/useLocalStorageState';
import {
	NotificationEventPayload,
	NotificationModel,
	NotificationSeverity,
} from '@/src/modules/common/models/notifications.models';
import { countMinutesBetweenDates } from '@/src/modules/common/utils';
import { useQueue } from '@uidotdev/usehooks';
import { v4 } from 'uuid';

const NOTIFICATION_EVENT_TYPE = 'NOTIFICATION_EVENT_TYPE';
const STORED_PERMANENT_NOTIFICATIONS_LOCALSTORAGE_KEY =
	'jugru.org.notifications.permanent-notifications';

export const useNotifications = () => {
	const { add, remove, first, size: notificationsQueueSize } = useQueue<NotificationModel>([]);

	const [notifications, setNotifications] = useState<NotificationModel[]>([]);
	const [storedPermanentNotifications, setStoredPermanentNotifications] = useLocalStorageState<
		NotificationModel[]
	>(STORED_PERMANENT_NOTIFICATIONS_LOCALSTORAGE_KEY, []);

	const unreadNotifications = useMemo(() => {
		return notifications.filter((note) => !note.isRead);
	}, [notifications]);

	const notShowedNotifications = useMemo(() => {
		return notifications.filter(
			(note) => note.isShowed === false || note.isShowed === undefined,
		);
	}, [notifications]);

	const addNote = (
		message: string,
		title: string,
		importance: NotificationSeverity,
		timeToLiveInMin?: number,
		isShowed?: boolean,
		isRead?: boolean,
		isPermanent?: boolean,
	) => {
		const note: NotificationModel = {
			id: v4(),
			title,
			message,
			created: new Date(),
			importance,
			timeToLiveInMin: isPermanent ? undefined : timeToLiveInMin,
			isShowed,
			isRead,
			isPermanent,
		};

		add(note);

		if (isPermanent) {
			setStoredPermanentNotifications([...(storedPermanentNotifications ?? []), note]);
		}
	};

	useEffect(() => {
		if (first) {
			setNotifications([...notifications, first]);
			remove();
		}
	}, [notificationsQueueSize, notifications]);

	const updateNotification = useCallback(
		(notification: NotificationModel) => {
			setNotifications([
				...notifications.filter((n) => n.id !== notification.id),
				notification,
			]);

			if (
				notification.isPermanent &&
				storedPermanentNotifications?.some((n) => n.id === notification.id)
			) {
				setStoredPermanentNotifications([
					...storedPermanentNotifications.filter((n) => n.id !== notification.id),
				]);
			}
		},
		[notifications, storedPermanentNotifications],
	);

	const clearNotes = () => {
		for (const notification of notifications.filter(
			(n) => !n.isRead && n.timeToLiveInMin !== undefined && !n.isPermanent,
		)) {
			const diff = countMinutesBetweenDates(notification.created, new Date());
			if (notification.timeToLiveInMin && diff >= notification.timeToLiveInMin) {
				updateNotification({ ...notification, isRead: true, isShowed: true });
			}
		}
	};

	const markAsRead = useCallback(
		(id: string) => {
			const notification = notifications.find((note) => note.id === id);
			if (notification) {
				updateNotification({ ...notification, isRead: true });
			}
		},
		[updateNotification],
	);

	const markAsShowed = useCallback(
		(id: string) => {
			const notification = notifications.find((note) => note.id === id);
			if (notification) {
				updateNotification({ ...notification, isShowed: true });
			}
		},
		[updateNotification],
	);

	const eventListener = (event: CustomEvent<NotificationEventPayload>) =>
		addNote(
			event.detail.message,
			event.detail.title,
			event.detail.importance,
			event.detail.timeToLiveInMin,
			event.detail.isShowed,
			event.detail.isRead,
			event.detail.isPermanent,
		);

	useEffect(() => {
		addEventListener(NOTIFICATION_EVENT_TYPE, eventListener as EventListener);

		const clearNotesInterval = setInterval(() => clearNotes(), 10000);

		return () => {
			removeEventListener(NOTIFICATION_EVENT_TYPE, eventListener as EventListener);
			clearInterval(clearNotesInterval);
		};
	}, []);

	useEffect(() => {
		if (
			notifications &&
			storedPermanentNotifications?.length &&
			storedPermanentNotifications?.length > 0
		) {
			const notesToAdd: NotificationModel[] = [];
			storedPermanentNotifications.forEach((note) => {
				if (!notifications.some((n) => n.id === note.id)) {
					notesToAdd.push(note);
				}
			});
			setNotifications([...notifications, ...notesToAdd]);
		}
	}, [setNotifications]);

	return useMemo(
		() => ({
			unreadNotifications,
			notShowedNotifications,
			markAsRead,
			markAsShowed,
		}),
		[notifications],
	);
};
