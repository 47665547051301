'use client';

import React, { FC, PropsWithChildren } from 'react';

import classNames from 'classnames';

import { CommonStyledComponent } from '@/src/modules/common/types/common.types';
import { Link } from '@/src/modules/common/utils';
import { Locale } from '@/src/modules/i18n';
import { useSelectedLayoutSegments } from 'next/navigation';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';

export type LocaleSwitcherProps = {
	otherLocale: Locale;
	title: string | undefined;
	label: string;
} & CommonStyledComponent;

export const LocaleSwitcher: FC<PropsWithChildren<LocaleSwitcherProps>> = ({
	otherLocale,
	label,
	title,
	children,
	className,
}) => {
	const segments = useSelectedLayoutSegments();
	const href = `/${segments.join('/')}`;

	return (
		<>
			<Tooltip target=".locale-switcher-link" />

			<Link
				href={href}
				locale={otherLocale}
				className={classNames('locale-switcher-link', className, 'outline-none flex')}
				data-pr-tooltip={title}
				data-pr-position="bottom"
				style={{ marginRight: '-12px' }}
			>
				<Button
					text
					className="h-3rem w-3rem flex justify-content-center"
					rounded
					size="small"
				>
					{label}
					{children}
				</Button>
			</Link>
		</>
	);
};
