import { DispatchWithoutAction } from 'react';

import { AuthApiService } from '@/src/modules/auth/services/auth.api.service';
import { AccessTokenVerifier } from '@/src/modules/auth/token/AccessTokenVerifier';

export class AuthVerifyService {
	private authApiService: AuthApiService = new AuthApiService();

	private onSilentLogin?: DispatchWithoutAction;
	private onAnywaySilentLogin?: DispatchWithoutAction;

	public constructor(public readonly serverRaw?: string) {}

	public isCorrectLogged() {
		const verifier = new AccessTokenVerifier(this.serverRaw);

		return verifier.hasValue && !verifier.isExpired;
	}

	public refreshAuthData(): Promise<boolean> {
		const verifier = new AccessTokenVerifier(this.serverRaw);

		if (!verifier.hasValue) {
			return this.tryVerify();
		} else {
			if (verifier.isExpired) {
				return this.tryVerify();
			} else {
				return Promise.resolve(true);
			}
		}
	}

	public tryVerify(): Promise<boolean> {
		return this.authApiService
			.verify()
			.then((ok) => {
				if (!ok) {
					const verifier = new AccessTokenVerifier(this.serverRaw);

					return this.authApiService.silentLogin().then(() => {
						if (verifier.hasValue) {
							this.onAnywaySilentLogin?.();
						}

						return false;
					});
				}

				return Promise.resolve(true);
			})
			.then((silentLoginResult) => {
				if (silentLoginResult) {
					this.onSilentLogin?.();

					return true;
				}

				return false;
			})
			.catch(() => {
				return false;
			});
	}
}
