'use client';

import { useEffect, useState } from 'react';

import { getThemeType } from '@/src/modules/common/components/ThemeSwitcher/themes';
import { getCookie, setCookie } from '@/src/modules/common/utils';
import { effect, signal } from 'alien-signals';

export const defaultLightTheme = 'bootstrap4-light-blue';
export const defaultDarkTheme = 'bootstrap4-dark-blue';

const themeSignal = signal<string>();

const THEME_LOCAL_STORAGE_KEY = 'jugru.org.theme';

const loadTheme = (href: string) => {
	if (typeof document === 'undefined') {
		return;
	}

	const link = document.getElementById(APP_THEME_LINK_ID) as HTMLLinkElement;
	const html = document.getElementsByTagName('html')?.[0];

	if (!link || !html) {
		return;
	}

	link.href = href;

	const htmlThemeAttribute = getThemeType(href);

	html.removeAttribute('light');
	html.removeAttribute('dark');
	html.setAttribute(htmlThemeAttribute, '');
};

export const APP_THEME_LINK_ID = 'theme-link';

export type UseTheme = {
	currentTheme?: string;
	currentSchema?: 'light' | 'dark';
	setTheme: (theme: string) => void;
};

export const useTheme = (): UseTheme => {
	const [currentTheme, setCurrentTheme] = useState<string | undefined>(() => {
		const fromCookie = getCookie(THEME_LOCAL_STORAGE_KEY);

		themeSignal.set(fromCookie);

		return fromCookie;
	});

	const setTheme = (theme: string) => {
		const href = `/themes/${theme}/theme.css`;

		loadTheme(href);
		setCurrentTheme(theme);
		const cookieValue = `${theme}; path=/; max-age=${1000 * 60 * 60 * 24 * 365}`;

		setCookie(THEME_LOCAL_STORAGE_KEY, cookieValue);

		themeSignal.set(theme);
	};

	useEffect(() => {
		effect(() => {
			setCurrentTheme(themeSignal.get());
		});
	}, []);

	return {
		currentTheme,
		setTheme,
		currentSchema: currentTheme === defaultDarkTheme ? 'dark' : 'light',
	};
};
