'use client';

import { useEffect, useMemo, useState } from 'react';

import { useGET, UseGETReloadFunction } from '@/src/modules/common/hooks/useGET';
import { usePATCH } from '@/src/modules/common/hooks/usePATCH';
import { ApiOnceResponse } from '@/src/modules/common/models/common.models';
import { eventsActions } from '@/src/modules/events/actions/events.actions';
import { PatchProjectRequest, ProjectEntity } from '@/src/modules/events/models/projects.models';
import { projectEventsRoutes } from '@/src/modules/events/routes/events.api.routes';

export type UseProject = {
	project?: ProjectEntity;
	isLoading: boolean;
	reload: UseGETReloadFunction;
	error: unknown;
	patchProject: (data: PatchProjectRequest) => Promise<ProjectEntity | undefined | null>;
};

export const useProject = (projectId?: string, canGet = true): UseProject => {
	const [endpoint, setEndpoint] = useState<string>();
	const [project, setProject] = useState<ProjectEntity>();
	const [errorState, setErrorState] = useState<unknown>();

	const [isLoadingProject, setIsLoadingProject] = useState<boolean>(true);

	const { data, isLoading, reload, error } = useGET<ApiOnceResponse<ProjectEntity>, unknown>({
		endpoint,
		canGet: endpoint !== undefined && canGet,
	});

	const patchProjectPatch = usePATCH<unknown, any>({
		endpoint,
	});

	useEffect(() => {
		setErrorState(error);
	}, [error]);

	useEffect(() => {
		setIsLoadingProject(isLoading);
	}, [isLoading]);

	useEffect(() => {
		if (projectId) {
			setEndpoint(projectEventsRoutes.projectApiPath(projectId));
		}
	}, [projectId]);

	useEffect(() => {
		if (data?.data) {
			setProject(eventsActions.addRightsToProject(data.data));
		}
	}, [data?.data]);

	const patchProject = async (data: PatchProjectRequest) => {
		setIsLoadingProject(true);

		let projectResponse;

		try {
			projectResponse = (await patchProjectPatch(data))?.data;

			setProject({ ...projectResponse });
		} catch (err) {
			setErrorState(err);
		}

		setIsLoadingProject(false);

		return projectResponse;
	};

	return useMemo(
		() => ({
			project,
			isLoading: isLoadingProject,
			reload,
			error: errorState,
			patchProject,
		}),
		[
			{
				project,
				isLoadingProject,
				reload,
				errorState,
				patchProject,
			},
		],
	);
};
