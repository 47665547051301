import { LocaleMessageType } from '@/src/modules/common/models/common.models';
import { useTranslations } from 'next-intl';

export type Locale = 'ru' | 'en';
export const locales: Locale[] = ['en', 'ru'];
export const defaultLocale: Locale = 'ru';

export const withLocale = (url: string, locale: Locale | string) => {
	if (url.startsWith(locale)) {
		return `/${url}`;
	}

	if (url.startsWith(`/${locale}`)) {
		return url;
	}

	if (url.startsWith('/')) {
		return `/${locale}${url}`;
	}

	return `/${locale}/${url}`;
};

export const localizeFrom = (
	from: LocaleMessageType | undefined,
	locale: Locale | string,
	defaultValue?: string,
): string => {
	if (!from) {
		return '';
	}

	const correctLocale = locale === 'RUS' ? 'ru' : locale === 'ENG' ? 'en' : locale;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	return from[correctLocale] ?? defaultValue ?? '';
};

export const useAnyTranslations = (namespace?: string) => {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const t = useTranslations(namespace);

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	return (anyKey: string) => t(anyKey);
};
