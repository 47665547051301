'use client';

import { FC } from 'react';

import { AuthApiService } from '@/src/modules/auth/services/auth.api.service';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';

export type LogoutProps = {
	logoutTip: string;
};

export const Logout: FC<LogoutProps> = ({ logoutTip }) => {
	return (
		<>
			<Tooltip target=".user-logout" />

			<Button
				icon="pi pi-sign-out"
				rounded
				text
				aria-label="Logout"
				className="text-color user-logout"
				style={{ marginRight: '-8px' }}
				data-pr-tooltip={logoutTip}
				data-pr-position="bottom"
				onClick={() =>
					new AuthApiService().logout()?.then(() => (window.location.href = '/'))
				}
			/>
		</>
	);
};
