'use client';

import { useEffect, useMemo, useState } from 'react';

import { useGET, UseGETReloadFunction } from '@/src/modules/common/hooks/useGET';
import { ApiOnceResponse } from '@/src/modules/common/models/common.models';
import { eventsActions } from '@/src/modules/events/actions/events.actions';
import { SeasonEntity } from '@/src/modules/events/models/seasons.models';
import { projectEventsRoutes } from '@/src/modules/events/routes/events.api.routes';

export type UseSeason = {
	season?: SeasonEntity;
	isLoading: boolean;
	reload: UseGETReloadFunction;
	error: unknown;
};

export const useSeason = (seasonId?: number, canGet = true): UseSeason => {
	const [endpoint, setEndpoint] = useState<string>();
	const [season, setSeason] = useState<SeasonEntity>();

	const { data, isLoading, reload, error } = useGET<ApiOnceResponse<SeasonEntity>, unknown>({
		endpoint,
		canGet: endpoint !== undefined && canGet,
	});

	useEffect(() => {
		if (seasonId) {
			setEndpoint(projectEventsRoutes.seasonApiPath(seasonId));
		}
	}, [seasonId]);

	useEffect(() => {
		if (data?.data) {
			setSeason(eventsActions.addRightsToSeason(data.data));
		}
	}, [data?.data]);

	return useMemo(
		() => ({
			season,
			isLoading,
			reload,
			error,
		}),
		[
			{
				season,
				isLoading,
				reload,
				error,
			},
		],
	);
};
