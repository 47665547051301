'use client';

import React, { FC, useEffect, useMemo, useRef } from 'react';

import { useNotifications } from '@/src/modules/common/hooks/useNotifications';
import { NotificationModel } from '@/src/modules/common/models/notifications.models';
import { Button } from 'primereact/button';
import { MenuItem } from 'primereact/menuitem';
import { TieredMenu } from 'primereact/tieredmenu';
import { Toast } from 'primereact/toast';

interface NotificationMenuProp {
	notificationsTip: string;
}

const NotificationMenu: FC<NotificationMenuProp> = ({ notificationsTip }) => {
	const { unreadNotifications, notShowedNotifications, markAsRead, markAsShowed } =
		useNotifications();

	const toast = useRef<Toast>(null);
	const menu = useRef<TieredMenu>(null);

	const renderMenuItem = (item: NotificationModel) => {
		const icon =
			item.importance === 'warn'
				? 'pi pi-exclamation-triangle'
				: item.importance === 'error'
					? 'pi pi-bolt'
					: 'pi pi-check-circle';

		return {
			template: (
				<div className="p-menuitem-content">
					<a className="flex p-menuitem-link">
						<i
							className={icon}
							style={{
								fontSize: '1rem',
								color:
									item.importance === 'warn'
										? 'yellow'
										: item.importance === 'error'
											? 'red'
											: 'green',
							}}
						></i>
						<div className="flex flex-column ml-4">
							<span className="font-bold">{item.title}</span>
							<span className="mt-2">{item.message}</span>
						</div>
						<Button
							className="ml-auto"
							icon="pi pi-check-square"
							rounded
							text
							onClick={() => markAsRead(item.id)}
						/>
					</a>
				</div>
			),
		} as MenuItem;
	};

	const notificationMenuItems = useMemo(
		() => unreadNotifications.map(renderMenuItem),
		[unreadNotifications],
	);

	useEffect(() => {
		notShowedNotifications.forEach((note) => {
			toast.current?.show({
				severity: note.importance,
				summary: note.title,
				detail: note.message,
				life: 3000,
			});
			markAsShowed(note.id);
		});
	}, [notShowedNotifications]);

	return (
		<>
			{notificationMenuItems.length > 0 && (
				<TieredMenu
					className="w-20rem"
					pt={{ menuitem: { className: 'white-space-nowrap' } }}
					model={notificationMenuItems}
					popup
					ref={menu}
					id="popup_menu_left"
				/>
			)}
			<Button
				disabled={notificationMenuItems.length === 0}
				icon="pi pi-bell"
				badgeClassName="p-badge-danger"
				badge={
					unreadNotifications.length > 0
						? unreadNotifications.length.toString()
						: undefined
				}
				rounded
				text
				aria-label="Notifications"
				className="text-color user-action"
				data-pr-tooltip={notificationsTip}
				data-pr-position="bottom"
				onClick={(event) => menu.current?.toggle(event)}
			/>
			<Toast ref={toast} />
		</>
	);
};

export default NotificationMenu;
