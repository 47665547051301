'use client';

import { FC } from 'react';

import NotificationMenu from '@/src/modules/common/components/Notifications/NotificationMenu';
import { routes } from '@/src/modules/common/routes/common.routes';
import { Routes } from '@/src/modules/common/routes/common.routes.types';
import { Link } from '@/src/modules/common/utils';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';

export type UserActionsProps = {
	settingsTip: string;
	notificationsTip: string;
	supportTip: string;
};

export const UserActions: FC<UserActionsProps> = ({
	settingsTip,
	notificationsTip,
	supportTip,
}) => (
	<>
		<Tooltip target=".user-action" />

		<Link href={routes[Routes.Settings].path} className="outline-none">
			<Button
				icon="pi pi-sliders-h"
				rounded
				text
				aria-label="Options"
				className="text-color user-action"
				style={{ marginRight: '-12px' }}
				data-pr-tooltip={settingsTip}
				data-pr-position="bottom"
			/>
		</Link>

		<NotificationMenu notificationsTip={notificationsTip} />

		<Link href={routes[Routes.Support].path} className="outline-none">
			<Button
				icon="pi pi-question-circle"
				rounded
				text
				aria-label="Support"
				className="text-color user-action"
				style={{ marginLeft: '-12px' }}
				data-pr-tooltip={supportTip}
				data-pr-position="bottom"
			/>
		</Link>
	</>
);
