const ChevronUpIcon = () => (
	<svg
		style={{ transform: 'rotate(180deg)' }}
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M5 7.5L10 12.5L15 7.5"
			stroke="currentColor"
			strokeWidth="1.67"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default ChevronUpIcon;
