'use client';

import React, { FC, useRef } from 'react';

import { AuthApiService } from '@/src/modules/auth/services/auth.api.service';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';

export type MobileUserInfoProps = {
	displayName: string;
	email: string;
	logoutTip: string;
	settingsTip: string;
	notificationsTip: string;
	supportTip: string;
	image: React.JSX.Element;
	localeSwitcher: React.JSX.Element;
};

export const MobileUserInfo: FC<MobileUserInfoProps> = ({
	displayName,
	email,
	logoutTip,
	supportTip,
	settingsTip,
	notificationsTip,
	image,
	localeSwitcher,
}) => {
	const menuRight = useRef<Menu>(null);

	const items: MenuItem[] = [
		{
			template: () => (
				<div className="flex-column gap-1" style={{ padding: '12px 20px' }}>
					<div>{displayName}</div>
					<div className="text-xs opacity-70">{email}</div>
				</div>
			),
		},
		{
			separator: true,
		},
		{
			items: [
				{
					template: () => (
						<div
							style={{
								padding: '12px 20px',
								marginTop: '-32px',
								marginBottom: '8px',
							}}
						>
							{localeSwitcher}
						</div>
					),
				},
				{
					label: settingsTip,
					icon: 'pi pi-sliders-h',
					command: () => {
						///
					},
				},
				{
					label: notificationsTip,
					icon: 'pi pi-bell',
					command: () => {
						///
					},
				},
				{
					label: supportTip,
					icon: 'pi pi-question-circle',
					command: () => {
						///
					},
				},
				{
					label: logoutTip,
					icon: 'pi pi-sign-out',
					command: () =>
						new AuthApiService().logout()?.then(() => window.location.reload()),
				},
			],
		},
	];

	return (
		<>
			<Button
				rounded
				text
				aria-label="Menu"
				onClick={(event) => menuRight.current?.toggle(event)}
				className="p-0"
			>
				{image}
			</Button>

			<Menu model={items} popup ref={menuRight} id="userInfoMenu" popupAlignment="right" />
		</>
	);
};
