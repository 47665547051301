'use client';

import { useCallback, useEffect, useState } from 'react';

import { Theme, ThemeConfig, ThemesConfig } from '@/src/modules/common/models/common.models';
import {
	getStateFromLocalStorage,
	setStateToLocalStorage,
} from '@/src/modules/common/utils/localStorage';

const themesConfig: ThemesConfig = {
	light: {
		icon: 'pi pi-sun',
		href: '/themes/lara-light-indigo/theme.css',
		nextTheme: 'dark',
	},
	dark: {
		icon: 'pi pi-moon',
		href: '/themes/lara-dark-indigo/theme.css',
		nextTheme: 'light',
	},
};

const THEME_LOCAL_STORAGE_KEY = 'jugru.org.theme';

const loadTheme = (themeConfig: ThemeConfig) => {
	const link = document.getElementById(APP_THEME_LINK_ID) as HTMLLinkElement;

	if (!link) {
		return;
	}

	link.href = themeConfig.href;
};

const showBody = () => {
	setTimeout(() => {
		document.body.classList.remove('opacity-0');
		document.body.classList.add('fadein');
		document.body.classList.add('animation-duration-1000');

		setTimeout(() => {
			document.body.classList.remove('fadein');
			document.body.classList.remove('animation-duration-1000');
		}, 1000);
	}, 500);
};

export const APP_THEME_LINK_ID = 'theme-link';

export type UseTheme_DEPRECATED = {
	theme: Theme | undefined;
	themesConfig: ThemesConfig;
	toggle: () => void;
};

// DEPRECATED
const useTheme = (): UseTheme_DEPRECATED => {
	const [theme, setTheme] = useState<Theme>();

	const toggle = useCallback(() => {
		if (!theme) {
			return;
		}

		const currentThemeConfig = themesConfig[theme];
		const nextThemeConfig = themesConfig[currentThemeConfig.nextTheme];

		loadTheme(nextThemeConfig);

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		setStateToLocalStorage(currentThemeConfig.nextTheme, THEME_LOCAL_STORAGE_KEY);

		setTheme(currentThemeConfig.nextTheme);
	}, [themesConfig, theme]);

	useEffect(() => {
		const link = document.getElementById(APP_THEME_LINK_ID) as HTMLLinkElement;

		if (!link) {
			return;
		}

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const themeFromLocalStorage = getStateFromLocalStorage(THEME_LOCAL_STORAGE_KEY) as Theme;

		if (themeFromLocalStorage) {
			loadTheme(themesConfig[themeFromLocalStorage]);
			setTheme(themeFromLocalStorage);

			showBody();

			return;
		}

		if (link.href.endsWith(themesConfig.light.href)) {
			setTheme('light');
		}

		if (link.href.endsWith(themesConfig.dark.href)) {
			setTheme('dark');
		}

		showBody();
	}, []);

	return {
		theme,
		themesConfig,
		toggle,
	};
};
